import React, { Component , Fragment } from "react";
import { Parallax } from "react-parallax";

import catalog from '../produtosda25.json';

import Slider from "react-slick";
import { slickDot, slideSlick, } from "../page-demo/script";

import Header from "../component/header/Header";
import CounterOne from "../elements/counters/CounterOne";
import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/FooterTwo";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiCreditCard, FiSend} from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import CardProduct from '../component/common/CardProduct';
import { FaWhatsapp, SiGmail} from 'react-icons/all';
import { FaInstagram } from 'react-icons/fa';

const image1 =
  "/assets/images/bg/paralax/bg-image-3-overlay.jpg";

const SlideList = [
    {
        textPosition: 'text-center',
        title: 'Produtos da 25',
        description: 'Destino perfeito para quem procura eletrônicos de alta qualidade a preços acessíveis. Nossa loja, localizada no coração movimentado do distrito comercial da 25 de Março em São Paulo, é o local ideal para compradores que buscam o que há de mais recente e inovador em celulares, videogames e outros eletrônicos de ponta.',
        buttonText: 'Promoções',
        buttonLink: '#promotions'
    }
]

export const contactUs = [
    {url: 'https://wa.me/5511978648879', placeholder: '+55 11 97864-8879', social: <FaWhatsapp />},
    {url: 'https://www.instagram.com/produtosda25_/', placeholder: '@produtosda25_', social: <FaInstagram />},
    {url: 'mailto:produtosda25@gmail.com', placeholder: 'produtosda25@gmail.com', social: <SiGmail />},
]

export const paymentMethods = [
    'Cartão de crédito, Pix, Cartão de débito, Bitcoin',
]


const promotions = catalog.filter(product => product.isPromotion === true)
const products = catalog.filter(product => product.isPromotion === false)

const feedbacks = [
    {
        "name": "Amanda Santos",
        "feedback": "Amei comprar na sua loja! Os produtos são de ótima qualidade e a entrega foi super rápida. Com certeza voltarei a fazer compras aqui!"
    },
    {
        "name": "Rafael Oliveira",
        "feedback": "Fiquei impressionado com a variedade de produtos disponíveis na loja. Encontrei exatamente o que estava procurando. Recomendo a todos!"
    },
    {
        "name": "Carolina Almeida",
        "feedback": "Estou muito satisfeita com minha compra. Os preços são ótimos e o atendimento ao cliente foi excepcional. Parabéns pela excelente loja!"
    },
    {
        "name": "Pedro Silva",
        "feedback": "Essa loja me surpreendeu! Os produtos chegaram em perfeito estado e são exatamente como descritos. Sem dúvida, farei novas compras aqui."
    },
    {
        "name": "Isabella Castro",
        "feedback": "Recomendo essa loja para todos os meus amigos. O site é fácil de navegar, o processo de compra é simples e a entrega é sempre pontual. Adorei!"
    },
    {
        "name": "Lucas Costa",
        "feedback": "Encontrei a loja perfeita para comprar presentes. Os produtos são incríveis e o processo de compra foi muito tranquilo. Com certeza voltarei!"
    },
    {
        "name": "Gabriela Ferreira",
        "feedback": "Sou cliente fiel dessa loja. Sempre encontro produtos de alta qualidade e com preços justos. A equipe de atendimento também é muito prestativa."
    },
    {
        "name": "Gustavo Santos",
        "feedback": "Fiquei impressionado com a rapidez na entrega dos produtos que comprei. Além disso, a embalagem estava muito bem protegida. Ótimo trabalho!"
    },
    {
        "name": "Marina Oliveira",
        "feedback": "A loja oferece uma excelente experiência de compra online. Encontrei exatamente o que procurava e o processo de pagamento foi seguro e fácil."
    },
    {
        "name": "Matheus Lima",
        "feedback": "Essa loja se destaca pelo ótimo atendimento ao cliente. Sempre que precisei de ajuda, fui prontamente atendido e minhas dúvidas foram solucionadas."
    }
]

class CreativeAgency extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 5);
        return(
            <Fragment>
                <Helmet pageTitle="Produtos da 25" />
                <Header logo="dark" bgColor={'header-bg-dark'} />
                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency">
                    <Parallax bgImage={image1} strength={1000}>
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center header-bg-dark" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>
                {/* End Slider Area   */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pt--120 pb--140 bg_color--5">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 id="promotions" className="title mb_md--15 mb_lg--15">Ofertas da semana</h2>
                                        <p>Oferecemos uma variedade de promoções ao longo do ano, incluindo vendas sazonais, ofertas por tempo limitado e descontos exclusivos para nossos clientes fiéis. Fique de olho em nossas promoções semanais e ofertas relâmpago, onde você pode obter grandes descontos em produtos selecionados por tempo limitado.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {promotions.map((value, index) => (
                                                <div>
                                                    <CardProduct
                                                        key={index}
                                                        linkTo={value.linkTo}
                                                        image={value.image}
                                                        symbol={value.symbol}
                                                        price={value.price}
                                                        oldPrice={value.oldPrice}
                                                        name={value.name}
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--140 p pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Nossos números</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 id="products" className="title">Produtos</h2>
                                    <p>Ofertas incríveis e descontos em alguns dos nossos produtos mais populares. Seja para atualizar seus eletrônicos, renovar sua estação de trabalho ou simplesmente se presentear com algo especial, nossa seção de produtos tem algo para todos.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                                {products.map((value, index) => (
                                    <div className={'col-lg-4 col-md-6 col-sm-4 col-12'}>
                                        <CardProduct
                                            key={index}
                                            linkTo={value.linkTo}
                                            image={value.image}
                                            symbol={value.symbol}
                                            price={value.price}
                                            name={value.name}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--120">
                    <div className="row">
                        <div className="col-lg-12">
                            <Slider {...slideSlick}>
                                {feedbacks.map((value, index) => (
                                    <div className="rn-testimonial-light text-center">
                                        <div className="inner">
                                            <p>{value.feedback}</p>
                                        </div>
                                        <div className="author-info">
                                            <h6><span>{value.name}</span></h6>
                                        </div>
                                    </div>
                                ))}
                            </Slider>

                        </div>
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/*to-do: Adicionar marcas que vendemos*/}

                {/* Start Brand Area */}
                {/*<div className="rn-brand-area brand-separation bg_color--5 ptb--120">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12">*/}
                {/*                <BrandTwo />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Brand Area */}

                {/* Start Contact Us */}
                <div id="contact" className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">

                        <div className="row contactUs">
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiSend />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contatos</h4>
                                        <div className='contacts'>
                                            {contactUs.map((contact, index) => (
                                                <div>
                                                    <a key={index} href={contact.url}>
                                                        {contact.placeholder}
                                                    </a>
                                                    {contact.social}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_sm--50 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiCreditCard />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Formas de pagamento</h4>
                                        {paymentMethods.map((payment, index) => (
                                            <p key={index}>{payment}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                {/* End Contact Us */}

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeAgency;