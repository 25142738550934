import React from 'react';
import { Link } from 'react-router-dom';


function CardProduct({name, image, linkTo, symbol,  price, oldPrice = null}) {
    return (
        <a href={`${linkTo}`}>
            <div className={'card-product'}>
                    <img src={`${image}`} className={'image-product'} alt='Product Image' />
                    <p className={'h4 mt--10 text-center'}>{name}</p>
                    <div className={'prices'}>
                        <h4 className={'h5'}>{symbol} {price}</h4>
                        {oldPrice && <p className={'h6 oldprice'}>{oldPrice}</p>}
                    </div>
                <div className=""><Link to={linkTo} className="rn-button-style--2 rn-btn-small--2 btn-solid">Comprar</Link></div>
            </div>
        </a>
    );
}

export default CardProduct;