import React from 'react';

import catalog from '../produtosda25.json';

import Header from '../component/header/Header';
import {Redirect, useParams} from 'react-router-dom';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import {FiCreditCard, FiSend} from 'react-icons/fi';
import Helmet from '../component/common/Helmet';
import FooterTwo from '../component/footer/FooterTwo';
import { contactUs, paymentMethods } from './CreativeAgency';
import {FaWhatsapp} from 'react-icons/all';

const DEFAULT_MESSAGE = 'Olá, me interessei pelo produto: '

function ProductPage(props) {
    const { productId } = useParams();
    const product = catalog.filter(product => product.id === productId)[0]
    const linkToBuy = `https://api.whatsapp.com/send?phone=5511978648879&text=${DEFAULT_MESSAGE}*${product?.name}*`

    if (!product) return <Redirect to={'/404'} />

    return (
        <>
            <Helmet pageTitle={`${product?.name} | Produtos da 25`} />
            <div>
                <Header logo="dark" bgColor={'header-bg-dark'} />
            </div>
            <div className='container'>
                <div className='row mt--100 mb--100'>
                    <div className='col-md-6 col-sm-12 col-12'>
                        <Tabs className='product'>
                            <div className="default-image">
                                <TabPanel>
                                    <img src={`${product?.image}`} className={'image-product'} alt='Product Image' />
                                </TabPanel>
                                {product?.otherImages.map((image, index) => (
                                    <TabPanel key={index}>
                                        <img src={`${image}`} className={'image-product'} alt='Product Image' />
                                    </TabPanel>
                                ))}
                            </div>
                            <div className="gallery-products">
                                <TabList>
                                    <Tab className={'otherImage'}>
                                        <img src={`${product?.image}`}  alt='Product Image' />
                                    </Tab>
                                    {product?.otherImages.map((image, index) => (
                                    <Tab className={'otherImage'} key={index}>
                                        <img src={`${image}`}  alt='Product Image' />
                                    </Tab>
                                ))}
                                </TabList>
                            </div>
                        </Tabs>
                    </div>
                    <div className='details col-md-6 col-sm-12 col-12'>
                        <h1 className='h1'>{product?.name}</h1>
                        <h3 className='h3'>{product?.symbol} {product?.price}</h3>
                        {product?.description.map(description => (
                            <p className='description'>{description}</p>
                        ))}
                        {product?.conditions.map(condition => (
                            <li><strong>{condition}</strong></li>
                        ))}
                         <a href={linkToBuy} target="_blank" className="mt--20 rn-button-style--2 btn-solid">Comprar <FaWhatsapp /></a>
                    </div>
                </div>
            </div>
            <div className='mt-200'>
                <div id="contact" className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row contactUs">
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiSend />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contatos</h4>
                                        <div className='contacts'>
                                            {contactUs.map((contact, index) => (
                                                <div>
                                                    <a key={index} href={contact.url}>
                                                        {contact.placeholder}
                                                    </a>
                                                    {contact.social}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_sm--50 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiCreditCard />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Formas de pagamento</h4>
                                        {paymentMethods.map((payment, index) => (
                                            <p key={index}>{payment}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </>

    );
}

export default ProductPage;